<template>
  <div class="form-group">
    <label :for="generatedId"> {{ label }} </label>
    <div class="form-group__input-container">
      <input
        v-model="color"
        @input="updateValue"
        class="form-control"
        type="text"
        :id="generatedId"
        :placeholder="placeholder"
        :required="req"
        :error="name"
        tabindex="-1"
        v-maska="'!#XXXXXX'"
      />
      <errors :field="name" :errors="errors" />
    </div>
    <div class="form-group__color-container">
      <input @input="updateValue" type="color" v-model="color" />
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref, toRef, watch } from "@vue/runtime-core";
import Errors from "../../Technical/Errors.vue";
import { useStore } from "vuex";
export default {
  name: "custom-color-picker",
  components: { Errors },
  props: {
    modelValue: {
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    req: {
      default: false,
    },
  },
  setup(props, context) {
    let generatedId = ref("input"),
      color = ref(""), modelValue = toRef(props, "modelValue");
    let errors = computed(() => store.state.errors);
    const store = useStore();

    const generateId = () => {
      let alphabet = "abcdefghijklmnopqrstuvwxyz";
      for (let i = 0; i < 6; i++) {
        generatedId.value +=
          alphabet[Math.round(Math.random() * (alphabet.length - 1))];
      }
    };
    const updateValue = () => {
      context.emit("update:modelValue", color.value);
    };

    onBeforeMount(() => {
      generateId();
      color.value = modelValue.value;
    });

    watch(
      () => modelValue.value,
      () => {
        color.value = modelValue.value;
      }
    );

    return {
      generateId,
      generatedId,
      updateValue,
      color,
      errors,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  display: flex;
  margin: 10px -15px;
  font-size: 15px;
  font-weight: 400;
  justify-content: center;

  label {
    padding: 8px 15px 9px 15px;
    flex: 0 0 25%;
    word-break: break-word;
  }

  &__input-container {
    padding-right: 15px;
    flex: 0 1 68%;
    align-self: center;
  }

  &__color-container {
    flex: 0 1 7%;

    input {
      height: 100%;
      background-color: #fff;
      border: 2px solid #ebebeb;
    }
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.56rem 1.3rem;

    line-height: 1;
    color: #878787;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid #ebebeb;
    // border-radius: 5px;
    -webkit-transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
      -webkit-box-shadow 0.15s ease-in-out;

    &:focus,
    &:focus-within {
      border-color: #89e4bf;
    }

    &__tel {
      display: flex;
    }
  }

  @media (max-width: 973px) {
    flex-direction: column;
    align-items: flex-start;

    label {
      flex: 1;
      padding: 15px;
    }
    &__input-container {
      padding-left: 15px;
      width: 100%;
      flex: 1;
    }
    .form-control {
      font-size: 16px;
    }
  }
}
</style>